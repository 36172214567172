import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { OPTIVAL_CONSTANTS } from "./Constants";

const defaultLanguageCode =
localStorage.getItem(OPTIVAL_CONSTANTS.LANGUAGE_CODE_SESSION_KEY) ||
  OPTIVAL_CONSTANTS.DEFAULT_LANGUAGE_CODE;

i18n.use(initReactI18next).init({
  fallbackLng: OPTIVAL_CONSTANTS.DEFAULT_LANGUAGE_CODE,
  lng: defaultLanguageCode,
  resources: {
    en: {
      translations: require("./../languages/english.json"),
    },
    ta: {
      translations: require("./../languages/tamil.json"),
    },
    te: {
      translations: require("./../languages/telugu.json"),
    },
    kn: {
      translations: require("./../languages/kannada.json"),
    },
    hi: {
      translations: require("./../languages/hindi.json"),
    },
    de: {
      translations: require("./../languages/german.json"),
    },
    fr: {
      translations: require("./../languages/french.json"),
    },
    th: {
      translations: require("./../languages/thai.json"),
    },
    zh: {
      translations: require("./../languages/mandarin.json"),
    },
    ms: {
      translations: require("./../languages/malay.json"),
    },
    ml: {
      translations: require("./../languages/malayalam.json"),
    },
    mr: {
      translations: require("./../languages/marathi.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "ta", "te", "kn", "hi", "de", "fr", "th", "zh", "ms", "ml", "mr"];

export default i18n;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { SpinnerContext } from "../../../../../shared/SpinnerContext";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Order, TableHeadCell } from "../../../../../shared/models/TableModels";
import { TableLoading } from "../../../../../shared/components/skeleton/table-loading/TableLoading";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { OPTIVAL_CONSTANTS } from "../../../../../shared/Constants";
import { useAPI } from "../../../../../shared/services/api/API";
import { SharedApi } from "../../../../../shared/services/shared-api/SharedApi";
import { AddSkillsDialog } from "../../add-skills/AddSkillsDialog";
import { useNavigate } from "react-router-dom";
import { SkillsContext } from "../../../contexts/SkillsContext";
import { tableSorting } from "../../../../../shared/utils/Utils";
import { dummySkillsLevels, ISkillsLevel } from "../../../models/SkillsData";
import RemoveIcon from '@mui/icons-material/Remove';

export const UserMatrixList = (Props?: any) => {
  const { isSpinnerShow } = useContext(SpinnerContext);
  const { httpGet, handleAlertBar, httpPost } = useAPI();
  const { t } = useTranslation();
  const [displayTableData, setDisplayTableData] = useState<any[]>([]);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [skillsLevels, setSkillsLevels] = useState<ISkillsLevel[]>(dummySkillsLevels);
  const { getColorForLevel } = SharedApi();
  const navigate = useNavigate();
  const [checkedUserGroupList, setCheckedUserGroupList] = useState<any>([]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    Props?.setPage(newPage);
    const startIndex = newPage * Props?.rowsPerPage;
    setDisplayTableData([
      ...Props?.tableData.slice(startIndex, startIndex + Props?.rowsPerPage),
    ]);
  };

  useEffect(() => {
    Props?.getSkillsMatrixUsers();
  }, []);

  const handleRowRemove = (row?: any) => {
    const remainingEmpId = Props?.tableData.filter((item: any) => item?.empId !== row?.empId).map((item: any) => item?.empId);
    const existPayload = sessionStorage.getItem("userPayload");
    let formatExistPayload;
    if(existPayload) {
      formatExistPayload = JSON.parse(existPayload);
    }
    const reqBody = {
      "employeeIds": remainingEmpId,
      "groupIds": [],
      "skillIds": formatExistPayload?.skillIds ? formatExistPayload?.skillIds : []
    }
    Props?.handleRemoveUserAlertDialogOpen(reqBody);
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    Props?.setRowsPerPage(parseInt(event.target.value, 10));
    Props?.setPage(0);
    setDisplayTableData(Props?.tableData.slice(0, parseInt(event.target.value, 10)));
  };

  const goToWorkflow = (row: any, skillsName: any) => {
    const skillsDetail = Props?.skillsMatrixUsersHeader.filter((header: any) => header.label === skillsName);
    navigate('../instrukt', {state: {row: skillsDetail[0]}})
  }

  useMemo(() => {
    const propertyType = orderBy === "usersAssigned" ? "number" : "string";
    const sortedList = tableSorting(Props?.tableData, order, orderBy, propertyType);
    Props?.setTableData(sortedList);
    setDisplayTableData(
      sortedList.slice(Props?.page * Props?.rowsPerPage, Props?.page * Props?.rowsPerPage + Props?.rowsPerPage)
    );
  }, [order, orderBy, Props?.page, Props.tableData]);

  const checkedUserGroup = (data: any): boolean => {
    console.log(data)
    if (!checkedUserGroupList.length) {
      return false;
    }
    const arr = checkedUserGroupList.filter(
      (res: any) => res['Users/Groups'] === data['Users/Groups']
    );
    return arr.length > 0 ? true : false;
  };

  const allCheckedUserGroup = (): boolean => {
    if (
      checkedUserGroupList.length === 0 &&
      displayTableData.length === 0
    )
      return false;
    for (let workflow of displayTableData) {
      const isPresent = checkedUserGroupList.some(
        (data: any) => data.id === workflow.id
      );

      if (!isPresent) {
        return false;
      }
    }
    return true;
  };

  const handleAllCheckBoxForUserGroup = (event: any) => {
    if (event.target.checked) {
      const uniqueUserGroupList: any = [];
      displayTableData.forEach((list: any) => {
        const index = checkedUserGroupList.findIndex(
          (checkedUnWorkFlow: any) =>
            checkedUnWorkFlow['Users/Groups'] === list['Users/Groups']
        );
        if (index === -1) {
          uniqueUserGroupList.push(list);
        }
      });
      setCheckedUserGroupList([
        ...checkedUserGroupList,
        ...uniqueUserGroupList,
      ]);
    } else {
      setCheckedUserGroupList([]);
    }
  };

  const handleCheckBoxForUserGroup = (event: any, data: any) => {
    if (event.target.checked) {
      setCheckedUserGroupList([...checkedUserGroupList, data]);
    } else {
      const index = checkedUserGroupList.findIndex(
        (workflow: any) => workflow['Users/Groups'] === data['Users/Groups']
      );
      checkedUserGroupList.splice(index, 1);

      setCheckedUserGroupList([...checkedUserGroupList]);
    }
  };

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TableContainer className="table-container">
          <Table stickyHeader size="medium" aria-label="Skill table">
            <TableHead>
              <TableRow key="skill-table-header">
                {Props?.skillsMatrixUsersHeader.map((headCell: TableHeadCell) => (
                  <React.Fragment key={headCell.id}>
                    {headCell?.label === "Users/Groups" ? (
                      <TableCell key={headCell.id} className="py-1 fw-bold">
                        {t(headCell.label)}
                      </TableCell>
                    ) : (<TableCell align="center" key={headCell.id} className="fw-bold" sx={{
                      maxWidth: "100px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap"
                    }} 
                    title={t(headCell.label)}>
                      {t(headCell.label)}
                    </TableCell>)}
                  </React.Fragment>
                ))}
                <TableCell align="center" sx={{ width: "8%" }}>{t("opva.remove")}</TableCell>
              </TableRow>
            </TableHead>
            {isSpinnerShow ? (
              <TableLoading column={Props?.skillsMatrixUsersHeader.length} />
            ) : (
              <TableBody>
                {displayTableData && displayTableData.length ? (
                  displayTableData.map((row: any, index) => (
                    <TableRow key={index}>
                      {Props?.skillsMatrixUsersHeader.map((header: any) => (
                        <React.Fragment key={header.id}>
                          {header?.label === "Users/Groups" ? (
                            <TableCell align="left">
                              <div className="d-flex">
                              <img
                                src="images/User.svg"
                                alt="User"
                                className="me-2"
                                width={'25px'} height={'25px'}
                              />
                              <Typography
                                sx={{ 
                                  maxWidth: "100px", 
                                  whiteSpace: "nowrap", 
                                  textOverflow: "ellipsis",
                                  overflow: "hidden", 
                                }}
                                title={row[header.label]}
                                
                                variant="bodySmall"
                              >
                                {row[header.label]}
                              </Typography>
                            </div>
                            </TableCell>
                          ) : row[header.label] ? (
                            <TableCell align="center">
                              <span style={{
                                  backgroundColor: getColorForLevel(
                                    row[header.label]
                                  ),
                                  borderRadius:"4px",
                                }} className="pt-2 pb-2 px-3 fw-bold">{row[header.label]}</span>
                            </TableCell>
                          ) : (
                            <TableCell align="center" key={header.label}>
                              <Link component="button" title={t("opva.action")}>
                                <img src={"images/" + 'doubleArrowUp.svg'} alt="Profile" width={'25.44px'} height={'21.58px'} onClick={() => goToWorkflow(row, header?.label)} />
                              </Link>
                            </TableCell>
                          )}
                        </React.Fragment>
                      ))}
                      <TableCell align="center">
                        <RemoveIcon
                                  sx={{ cursor: "pointer" }}
                                  color="error"
                                  onClick={() => {
                                    handleRowRemove(row);
                                  }}
                                />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow sx={{height: '400px'}}>
                    <TableCell colSpan={8} align="center">
                      {t("opva.noDataAvailable")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
                className="pagination-container mb-4"
                component="div"
                count={Props?.tableData?.length}
                page={Props?.page}
                onPageChange={handleChangePage}
                rowsPerPage={Props?.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Grid
        container
        display={"flex"}
        justifyContent={"flex-end"}
        className="pb-2"
        spacing={2}
        direction={"row"}
      >
        {skillsLevels.length > 0 &&
          skillsLevels.map((level: any, index: number) => (
            <Grid
              item
              key={index}
              display={"flex"}
              style={{ flexDirection: "row" }}
              alignItems={'center'}
            >
              <Typography
                variant="h6"
                width={'32px'}
                height={'32px'}
                textAlign={'center'}
                sx={{ backgroundColor: getColorForLevel(level?.id), borderRadius:"4px", paddingTop:'6px'}}
              >
                {level?.id}
              </Typography>
              <Typography variant="h6" className="p-2">
                {t(`opva.${level?.name}`)}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WorkflowAvailableProps } from "../../../models/SkillsData";
import "./AvailableWorkflow.scss";
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../../../shared/Constants";
import {
  WorkflowConstants,
  WorkflowListModel,
} from "../../../../workflow/models/Workflow";
import { Order } from "../../../../../shared/models/TableModels";
import { tableSorting } from "../../../../../shared/utils/Utils";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export const AvailableWorkflow = (props: WorkflowAvailableProps) => {
  const {
    levelId,
    levelName,
    row,
    collapseClose,
    selectGroupsList,
    workflowDataList,
    eligibleUsersDetail,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { httpGet, httpPost, handleAlertBar } = useAPI();
  const [workflowDataVisible, setWorkflowDataVisible] = useState<
    WorkflowListModel[]
  >([]);
  const [perPageRows, setPerPageRows] = useState<number>(5);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [anchorGroupEl, setGroupAnchorEl] = useState<null | HTMLElement>(null);
  const openGroup = Boolean(anchorGroupEl);

  const handleGroupClick = (
    event: React.MouseEvent<HTMLElement>,
    groupId: number
  ) => {
    setGroupAnchorEl(event.currentTarget);
  };
  const handleGroupClose = () => {
    setGroupAnchorEl(null);
  };

  const viewAllWorkflows = () => {
    navigate("../instrukt", {
      state: {
        row: { id: row?.skillId, label: row.skillName, levelName: levelName, levelId: levelId },
      },
    });
  };

  const assignWorkflows = async () => {
    const reqBody = {
      workflowIds: selected,
      groupIds: [selectGroupsList[0].groupId],
    };
    await httpPost(
      `${OPTIVAL_CONSTANTS.API_URLS.SKILLS_ASSIGNED_WORKFLOW_GROUPS}`,
      reqBody
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
          handleAlertBar("success", t("opva.workflowAssignedGroupSuccessfully."));
          collapseClose();
        }
      },
      (error) => {}
    );
  };

  const handleSelectCheckbox = (
    event: ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const removeSelectedWorkflow = () => {
    setSelected([]);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const renderTruncateTitle = (title: string, whereTo: string): string => {
    return title.length > WorkflowConstants.WORKFLOW_TITLE_LENGTH
      ? whereTo === "display"
        ? title.substring(0, WorkflowConstants.WORKFLOW_TITLE_LENGTH) + "..."
        : title
      : whereTo === "display"
      ? title
      : "";
  };
  const toggleDescriptionText = (workflowData: WorkflowListModel) => {
    workflowData.isDescriptionViewMore = !workflowData.isDescriptionViewMore;
    setWorkflowDataVisible([...workflowDataVisible]);
  };

  const renderTruncateDescription = (workflowData: WorkflowListModel) => {
    return !workflowData.isDescriptionViewMore ? (
      <>
        {workflowData.description.substring(
          0,
          WorkflowConstants.WORKFLOW_DESCRIPTION_LENGTH
        )}{" "}
        <Link
          component="button"
          onClick={() => toggleDescriptionText(workflowData)}
        >
          {t("opva.viewMore")}
        </Link>
      </>
    ) : (
      <>
        {workflowData.description}{" "}
        <Link
          component="button"
          onClick={() => toggleDescriptionText(workflowData)}
        >
          {t("opva.viewLess")}
        </Link>
      </>
    );
  };

  useMemo(() => {
    const propertyType = ["groupsAssigned", "noOfVersions"].includes(orderBy)
      ? "number"
      : "string";
    const sortedList = tableSorting(
      workflowDataList,
      order,
      orderBy,
      propertyType
    );
    setWorkflowDataVisible(
      sortedList.slice(page * perPageRows, page * perPageRows + perPageRows)
    );
  }, [order, orderBy, page, perPageRows, workflowDataList]);

  return (
    <React.Fragment>
      <div className="available-workflow-container">
        <Grid container>
          <Grid item xs={6} md={10}>
            <Typography variant="h6" className="mb-3">
              {t(`opva.${levelId}WorkflowsAvailable`)}
            </Typography>
          </Grid>
          <Grid item xs={4} md={1.5}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ textTransform: "none", backgroundColor: "#FFFF" }}
              onClick={viewAllWorkflows}
              disabled={workflowDataVisible.length === 0}
            >
              {t("opva.viewAllWorkflows")}
            </Button>
          </Grid>
          <Grid item xs={2} md={0.5}>
            <Link
              component="button"
              className="float-end"
              title="Close"
              onClick={collapseClose}
            >
              <CloseIcon />
            </Link>
          </Grid>
        </Grid>
        <div className="d-flex align-items-center justify-content-start pb-8">
          <Typography variant="bodySmall">{`${selected.length}/${workflowDataList.length} selected`}</Typography>
        </div>
        {selected?.length > 0 && (
          <Typography
            variant="bodySmall"
            color={"#0084F4"}
            onClick={() => removeSelectedWorkflow()}
          >
            {t("opva.deselectAll")}
          </Typography>
        )}
        <TableContainer className="workflow-table-container">
          <Table
            stickyHeader
            sx={{ width: "100%", overflow: "auto", maxHeight: "20%" }}
            size="medium"
            aria-label="Workflow table"
          >
            <TableBody>
              {workflowDataVisible && workflowDataVisible.length ? (
                workflowDataVisible.map((data: WorkflowListModel, index) => {
                  const isItemSelected = isSelected(data.id);
                  const labelId = `table-checkbox-${index}`;

                  return (
                    <Fragment key={index}>
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onChange={(event) =>
                              handleSelectCheckbox(event, data.id)
                            }
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell className="p-2">
                          <section className="d-flex align-items-center">
                            <img
                              src="images/User-Thumb.svg"
                              alt="User Icon"
                              width={40}
                              height={40}
                            />
                            <div className="w-100 ms-3">
                              <p
                                className="fw-bold mb-1"
                                title={renderTruncateTitle(data.name, "title")}
                              >
                                {renderTruncateTitle(data.name, "display")}
                              </p>
                              <label>Version {data.publishedVersion}</label>
                            </div>
                          </section>
                        </TableCell>
                        <TableCell className="p-2">
                          {data.description.length >
                          WorkflowConstants.WORKFLOW_DESCRIPTION_LENGTH
                            ? renderTruncateDescription(data)
                            : data.description}
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    {t("opva.noWorkflowDataAvailable")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          <Typography variant="h6" className="my-4">
            {t("opva.Groups/UsersSelected")}
          </Typography>
        </div>
        <div></div>
        <Grid container spacing={2} paddingBottom={3}>
          {selectGroupsList.length > 0 &&
            selectGroupsList.map((data: any, index: number) => (
              <Grid item xs={3} md={3} key={index}>
                <section className="assigned-user">
                  <div className="d-flex mb-3">
                    <img src="images/User-Thumb-Black.svg" alt="User" />
                    <div className="ms-2">
                      <Typography
                        variant="bodySmall"
                        className="d-block fw-bold"
                      >
                        {data.name}
                      </Typography>
                      <Typography variant="labelSmall" className="d-block">
                        {`${eligibleUsersDetail[data.groupId]?.eligibleUsers}/${eligibleUsersDetail[data.groupId]?.totalUsers} ${t("opva.usersEligibleSelected")}`}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ alignItems: "end" }}>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={openGroup ? "long-menu" : undefined}
                      aria-expanded={openGroup ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => handleGroupClick(e, data?.groupId)}
                      color="primary"
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorGroupEl}
                      open={openGroup}
                      onClose={handleGroupClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem onClick={handleGroupClose}>
                        <Link component="button" title={t("opva.notify")}>
                          <NotificationsNoneIcon />
                        </Link>{" "}
                        <Typography
                          variant="bodySmall"
                          className="d-block ms-2"
                        >
                          {t("opva.notify")}
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </div>
                </section>
              </Grid>
            ))}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          sx={{ textTransform: "none" }}
          onClick={assignWorkflows}
          disabled={selected.length === 0}
        >
          {t("opva.assignWorkflows&Upskill")}
        </Button>
      </div>
    </React.Fragment>
  );
};

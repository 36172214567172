import HighchartsReact from "highcharts-react-official";
import React, { useContext, useEffect, useState } from "react";
import Highcharts from "highcharts";
import "./BarChart.scss";
import { useAPI } from "../../../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../../../shared/Constants";
import { useTranslation } from "react-i18next";
import { Link, Typography } from "@mui/material";
import { SkillsContext } from "../../../contexts/SkillsContext";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

export const BarChart = () => {
  const { t } = useTranslation();
  const { httpGet, handleAlertBar } = useAPI();
  const [skills, setSkills] = useState([]);
  const [seriesData, setSeriesData] = useState<any>([]);
  const { updateSkillsBarChartLessMore } = useContext(SkillsContext);
  const getskillsGapChartData = () => {
    const lang = localStorage.getItem(OPTIVAL_CONSTANTS.EMPLOYEE_PREFERRED_LANGUAGE);
    const queryParams = {
      pageSize: 4,
      language: lang
    };
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS_WITH_GAP, queryParams).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar("error", response.data.message);
          } else {
            // set bar chart categories
            const skillName = response?.data?.skillsWithMostGap.map(
              (skill: any) => skill.skillName
            );
            setSkills(skillName);

            //set series data
            const target = response?.data?.skillsWithMostGap.map(
              (item: any) => item.target
            );
            const gap = response?.data?.skillsWithMostGap.map(
              (item: any) => item.gap
            );
            const achieved = response?.data?.skillsWithMostGap.map(
              (item: any) => item.achieved
            );

            setSeriesData([
              {
                name: t("opva.target"),
                data: target,
                stack: "target",
                color: "#00C48C",
              },
              {
                name: t("opva.gap"),
                data: gap,
                color: "#FBE4E8",
                borderColor: "#FF647C",
              },
              { name: t("opva.achieve"), data: achieved, color: "#0084F4" },
            ]);
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      }
    );
  };

  useEffect(() => {
    getskillsGapChartData();
  }, []);

  const options = {
    chart: {
      type: "column",
      backgroundColor: "#fafbfc",
      height: 300,
    },

    title: {
      text: t("opva.topSkillsGapAnalysis"),
      align: "left",
    },
    xAxis: {
      categories: skills,
      gridLineWidth: 1,
      gridLineDashStyle: "dash",
    },

    yAxis: {
      gridLineDashStyle: "dash",
      title: {
        text: "",
      },
    },
    legend: {
      align: 'left',
  },

    tooltip: {
      format: "{y}",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        stacking: "normal",
      },
    },

    series: seriesData,
  };
  return (
    <div className="bar-chart-container">
      <div style={{ position: "absolute", right: 20, top: 20, zIndex: 1}}>
      <Link
                component="button"
                color={"#00000"}
                title={"expand"}
                onClick={() => updateSkillsBarChartLessMore(true)}
              >
                <OpenInFullIcon fontSize="small" />
              </Link>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

import { createContext, useEffect, useMemo, useState } from "react";
import { SkillsContextModel } from "../models/SkillsContextModel";

export const SkillsContext = createContext(
  {} as SkillsContextModel
);

export const SkillsProvider = ({ children }: any) => {
  const [topViewMore, setTopViewMore] = useState(false);
  const [timeSpentViewMore, setTimeSpentViewMore] = useState(false);
  const [skillsGainViewMore, setSkillsGainViewMore] = useState(false);
  const [validatedViewMore, setValidatedViewMore] = useState(false);
  const [skillsBarChartViewMore, setSkillsBarChartViewMore] = useState(false);
  const [skillsDistributionPieChartViewMore, setSkillsDistributionPieChartViewMore] = useState(false);
  const [targetGapViewMore, setTargetGapViewMore] = useState(false);

  const updateTopSkillLessMore = (value: boolean) => {
    setTopViewMore(value);
  };

  const updateTimeSpentSkillLessMore = (value: boolean) => {
    setTimeSpentViewMore(value);
  };

  const updateSkillsGainSkillLessMore = (value: boolean) => {
    setSkillsGainViewMore(value);
  };

  const updateValidateLessMore = (value: boolean) => {
    setValidatedViewMore(value);
  };

  const updateSkillsBarChartLessMore = (value: boolean) => {
    setSkillsBarChartViewMore(value);
  };

  const updateSkillsDistributionPieChartLessMore = (value: boolean) => {
    setSkillsDistributionPieChartViewMore(value);
  };

  const updateTargetGapLessMore = (value: boolean) => {
    setTargetGapViewMore(value);
  };

  const authProviderValues = useMemo(
    () => ({ 
      topViewMore , 
      updateTopSkillLessMore, 
      timeSpentViewMore, 
      updateTimeSpentSkillLessMore, 
      skillsGainViewMore, 
      updateSkillsGainSkillLessMore,
      validatedViewMore,
      updateValidateLessMore,
      skillsBarChartViewMore,
      updateSkillsBarChartLessMore,
      skillsDistributionPieChartViewMore,
      updateSkillsDistributionPieChartLessMore,
      targetGapViewMore,
      updateTargetGapLessMore,
     }),
    [
      topViewMore, 
      timeSpentViewMore, 
      skillsGainViewMore, 
      validatedViewMore,
      skillsBarChartViewMore,
      skillsDistributionPieChartViewMore,
      targetGapViewMore,
    ]
  );

  return (
    <SkillsContext.Provider value={authProviderValues}>
      {children}
    </SkillsContext.Provider>
  );
};

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import "./AssignSkills.scss";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, KeyboardEvent, useMemo, useContext } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { visuallyHidden } from "@mui/utils";
import { Breadcrumb } from "../../../../../breadcrumb/components/Breadcrumb";
import { UserListModel } from "../../../../../new-workflow-creation/models/AssignWorkflowModel";
import { useAPI } from "../../../../../../shared/services/api/API";
import {
  tableSorting,
  updateURLParams,
} from "../../../../../../shared/utils/Utils";
import { OPTIVAL_CONSTANTS } from "../../../../../../shared/Constants";
import { DownloadExcelButton } from "../../../../../../shared/components/Download/DownloadExcelButton";
import { TableHeadCell } from "../../../../../../shared/models/TableModels";
import { TableLoading } from "../../../../../../shared/components/skeleton/table-loading/TableLoading";
import { useTranslation } from "react-i18next";
import { useMapAndAssignUtils } from "../../../../models/SkillsConstants";
import {
  EmployeeWithoutSkill,
  EmployeeWithSkill,
  SkillsToEmployee,
  SortObj,
} from "./models/SkillsToEmployee";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SkillsContext } from "../../../../contexts/SkillsContext";

export const AssignSkills = () => {
  const { updateValidateLessMore } = useContext(SkillsContext);
  const navigate = useNavigate();
  const { httpGet, httpPost, handleAlertBar, httpDelete } = useAPI();
  const [searchText, setSearchText] = useState("");
  const [isLoadingUnAssign, setIsLoadingUnAssign] = useState(true);
  const [unAssignpage, setUnAssignPage] = useState(0);
  const [unAssignRowsPerPage, setUnAssignRowsPerPage] = useState(5);
  const [unAssignedUsers, setUnAssignedUsers] = useState<SkillsToEmployee[]>(
    []
  );
  const [searchUserName, setSearchUserName] = useState<string>("");
  const [isUserSearching, setIsUserSearching] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState<SkillsToEmployee[]>([]);
  const [isLoadingAssign, setIsLoadingAssign] = useState(true);
  const [assignPage, setAssignPage] = useState(0);
  const [assignRowsPerPage, setAssignRowsPerPage] = useState(5);
  const [displayAssignedUsers, setDisplayAssignedUsers] = useState<
    SkillsToEmployee[]
  >([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [unAssignSort, setUnAssignSort] = useState<SortObj>({
    order: "desc",
    orderBy: "name",
  });
  const [assignSort, setAssignSort] = useState<SortObj>({
    order: "desc",
    orderBy: "name",
  });
  const [skillsLevel, setSkillsLevel] = useState([]);
  const [skillsValidation, setSkillsValidation] = useState([
    { id: 1, name: "VALIDATED" },
    { id: 2, name: "NOTVALIDATED" },
  ]);
  const [selectedSkill, setSelectedSkill] = useState<any>(null);
  const [selectedSkillValidation, setSelectedSkillValidation] =
    useState<any>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [assignData, setAssignData] = useState<SkillsToEmployee | null>();
  const { state } = useLocation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorMsg, setErrorMsg] = useState("");
  const excelHeader = [
    "Skill Name*",
    "Skill Level*",
    "Email address*",
    "Validated*",
  ];
  const excelListOptions = ["Beginner", "Intermediate", "Advanced", "Expert"];
  const validatedExcelListOptions = [true, false];
  const defaultValues = {
    A: state.skillName,
    B: "Beginner",
  };

  const { t } = useTranslation();
  const { getAssignWorkflowHeaders } = useMapAndAssignUtils();

  const handleUploadBoxDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleUploadBoxDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const dropFiles = e.dataTransfer.files[0];
    if (dropFiles.type.includes("sheet")) {
      setSelectedFile(dropFiles);
      setErrorMsg("");
    } else {
      setErrorMsg("Please Upload an excel sheet");
      setSelectedFile(null);
    }
  };

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
    }
    event.target.value = "";
  };

  const handleRemoveClick = () => {
    setSelectedFile(null);
  };

  const handleRemoveAssignUser = () => {
    setAssignData(null);
    getUnAssignedSkillsToEmployee("");
  };

  const handleBulkUser = async () => {
    const urlParams = {
      skillId: state.skillId,
    };
    const formData = new FormData();
    if (selectedFile) {
      formData.append("file", selectedFile);
    }
    await httpPost(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.ASSIGN_BULK_SKILL_EMPLOYEES,
        urlParams
      ),
      formData
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              t(`opva.${response?.data?.code}`) || t("opva.serviceUnavailable")
            );
          } else {
            handleAlertBar("success", t("opva.skillsAssignedSuccessfullyMsg"));
            setSelectedFile(null);
            getAssingedSkillsToEmployee();
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const backToSkillz = () => {
    navigate("/skillz");
    updateValidateLessMore(true);
  };

  const handleSearchKeyEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchText.length > 3) {
      getAssingedSkillsToEmployee();
    }
  };

  const handleChangePageForAssigned = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setAssignPage(newPage);
    const startIndex = newPage * assignRowsPerPage;
    setDisplayAssignedUsers([
      ...assignedUsers.slice(startIndex, startIndex + assignRowsPerPage),
    ]);
  };

  const handleChangeRowsPerPageForAssigned = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAssignRowsPerPage(parseInt(event.target.value, 10));
    setAssignPage(0);
    setDisplayAssignedUsers(
      assignedUsers.slice(0, parseInt(event.target.value, 10))
    );
  };

  const handleSorting = (property: string, table: string) => {
    if (table === "unAssignTable") {
      const isAsc =
        unAssignSort.orderBy === property && unAssignSort.order === "asc";
      setUnAssignSort({ order: isAsc ? "desc" : "asc", orderBy: property });
    } else if (table === "assignTable") {
      const isAsc =
        assignSort.orderBy === property && assignSort.order === "asc";
      setAssignSort({ order: isAsc ? "desc" : "asc", orderBy: property });
    }
  };

  const handleEmptyPage = (data: any, table: string) => {
    if (table === "assignedUser") {
      const sliceArr = data.slice(
        assignPage * assignRowsPerPage,
        assignPage * assignRowsPerPage + assignRowsPerPage
      );
      if (!sliceArr.length) {
        setAssignPage(assignPage === 0 ? 0 : assignPage - 1);
      }
    } else if (table === "unAssignedUser") {
      const sliceArr = data.slice(
        unAssignpage * unAssignRowsPerPage,
        unAssignpage * unAssignRowsPerPage + unAssignRowsPerPage
      );
      if (!sliceArr.length) {
        setUnAssignPage(unAssignpage === 0 ? 0 : unAssignpage - 1);
      }
    }
  };

  const queryParams = {
    page: 0,
    size: 1000,
    sortColumn: "modifiedAt",
    isDescending: true,
    searchString: searchText,
  };
  const getUnAssignedSkillsToEmployee = useMemo(
    () =>
      debounce((searchUser: string) => {
        const queryParams = {
          query: searchUser,
        };
        const assignedUserId = assignedUsers?.map((e: any) => e?.employee?.id);
        const requestBody = {
          userIds: assignedUserId,
          groupIds: [0],
        };
        httpPost(
          OPTIVAL_CONSTANTS.API_URLS.SEARCH_GROUP_USERS,
          requestBody,
          queryParams
        ).then(
          (response: any) => {
            if (response && response.data && !response.data?.error) {
              const employees = response.data
                .filter((item: EmployeeWithoutSkill) => item?.employee !== null)
                .map((item: EmployeeWithoutSkill) => item.employee);
              setUnAssignedUsers(employees);
            } else {
              handleAlertBar(
                "error",
                response?.data?.message || t("opva.serviceUnavailable")
              );
            }
          },
          (error: any) => {
            handleAlertBar(
              "error",
              error.message || t("opva.serviceUnavailable")
            );
          }
        );
      }, 500),
    [assignedUsers]
  );

  const getAssingedSkillsToEmployee = async () => {
    setIsLoadingAssign(true);
    const urlParams = {
      skillId: state.skillId,
      //skillId: 23,
    };
    await httpGet(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.GET_USER_ASSIGNED_FOR_SKILL,
        urlParams
      ),
      queryParams
    ).then(
      (response: any) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
            setIsLoadingAssign(false);
          } else {
            setAssignedUsers(response.data);
            setDisplayAssignedUsers(response.data.slice(0, assignRowsPerPage));
            setIsLoadingAssign(false);
            handleEmptyPage(response.data, "assignedUser");
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
          setIsLoadingAssign(false);
        }
      },
      (error: any) => {
        setIsLoadingAssign(false);
      }
    );
  };

  useEffect(() => {
    if (!openDialog) {
      setSelectedSkill(null);
      setSelectedSkillValidation(null);
    }
  }, [openDialog]);

  const assignSkillToEmployee = async (id: any) => {
    const requestBody = {
      employeeIds: [id],
      skillId: state.skillId,
      validated: selectedSkillValidation?.id === 1 ? true : false,
      skillLevelId: selectedSkill.id,
    };
    await httpPost(
      OPTIVAL_CONSTANTS.API_URLS.ADD_UNASSIGNED_SKILLS_TO_EMPLOYEE,
      requestBody
    ).then(
      (response: any) => {
        setSelectedSkill(null);
        setSelectedSkillValidation(null);
        setAssignData(undefined);
        if (response && response.status) {
          if (response.status === 200) {
            handleAlertBar("success", t("opva.skillAssignEmpSuccessfullyMsg"));
            getAssingedSkillsToEmployee();
            getUnAssignedSkillsToEmployee("");
            setOpenDialog(false);
          } else {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error: any) => {}
    );
  };

  const removeSkillFromEmployee = async (id: number) => {
    const requestBody = {
      employeeIds: [id],
      skillId: state.skillId,
      //skillId: 23,
    };
    httpDelete(
      OPTIVAL_CONSTANTS.API_URLS.REMOVE_SKILL_FROM_EMPLOYEE,
      requestBody
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        } else {
          handleAlertBar("success", t("opva.skillRemovedFromEmpSucceddfully"));
          getAssingedSkillsToEmployee();
        }
      },
      (error) => {}
    );
  };

  const handleTabChange = (_event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  const handleRemoveSkillFromEmployee = (data: EmployeeWithSkill) => {
    removeSkillFromEmployee(data.employee.id);
  };
  const getSkillsLevels = async () => {
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS_LEVELS).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
          setSkillsLevel(response.data);
        }
      },
      (error) => {
        handleAlertBar("error", error.message || t("opva.serviceUnavailable"));
      }
    );
  };

  const handleSkillChange = (event: any) => {
    const skill = skillsLevel.find(
      (skill: any) => skill?.id === parseInt(event.target.value, 10)
    );
    setSelectedSkill(skill);
  };

  const handleSkillValidationChange = (event: any) => {
    const skill = skillsValidation.find(
      (skill: any) => skill?.id === parseInt(event.target.value, 10)
    );
    setSelectedSkillValidation(skill);
  };

  const assignSkills = () => {
    setOpenDialog(true);
  };

  useMemo(() => {
    const propertyType =
      assignSort.orderBy === "employeeId" ? "number" : "string";
    const sortedList = tableSorting(
      assignedUsers,
      assignSort.order,
      assignSort.orderBy,
      propertyType
    );
    setAssignedUsers(sortedList);
    setDisplayAssignedUsers(
      sortedList.slice(
        assignPage * assignRowsPerPage,
        assignPage * assignRowsPerPage + assignRowsPerPage
      )
    );
  }, [assignSort, assignPage, assignedUsers]);

  const handleAssignUser = (value: any) => {
    setAssignData(value);
  };

  useEffect(() => {
    let timeOutId: number | undefined = undefined;
    if (searchText.length >= 3) {
      timeOutId = window.setTimeout(() => {
        getUnAssignedSkillsToEmployee("");
        getAssingedSkillsToEmployee();
      }, 1000);
    } else if (searchText.length === 0) {
      timeOutId = window.setTimeout(() => {
        queryParams.searchString = "";
        getUnAssignedSkillsToEmployee("");
        getAssingedSkillsToEmployee();
      }, 1000);
    }
    getSkillsLevels();
    return () => {
      window.clearTimeout(timeOutId);
    };
  }, [searchText]);

  return (
    <div className="assign-skillz-container">
      <div className="bg-light-blue pt-2 mb-3">
        <div className="px-4">
          <Breadcrumb
            isFromOPVA={true}
            pathList={[
              { name: t("opva.skillz"), path: "skillz" },
              { name: t("opva.assignSkill"), path: "assign-skill" },
            ]}
          />
          <Grid container alignItems="center" className="pt-2 pb-4">
            <Grid item xs md lg={6} className="d-flex align-items-center">
              <Link
                component="button"
                title={t("opva.backToSkill")}
                className="me-2"
                onClick={backToSkillz}
              >
                <KeyboardArrowLeftIcon fontSize="large" />
              </Link>
              <Typography variant="h4" className="mb-0">
                {t("opva.assign")}&nbsp;[{state.skillName}]
              </Typography>
            </Grid>
            <Grid
              item
              xs
              md
              lg={6}
              className="d-flex align-items-center justify-content-end"
            >
              <Input
                id="search"
                type="text"
                placeholder={t("opva.search")}
                value={searchText}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  searchText && (
                    <InputAdornment position="end">
                      <Link component="button" title={t("opva.clear")}>
                        <CloseIcon
                          fontSize="small"
                          onClick={() => setSearchText("")}
                        />
                      </Link>
                    </InputAdornment>
                  )
                }
                size="small"
                sx={{ paddingLeft: "10px" }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={handleSearchKeyEnter}
              />
            </Grid>
          </Grid>
        </div>
        <Box
          sx={{ width: "100%", typography: "body1" }}
          className="bg-white px-2"
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label={t("opva.user")} />
            {/* <Tab label="Groups" /> */}
          </Tabs>
        </Box>
        <Grid container spacing={2} className="bg-white px-4">
          <Grid item xs={12} md={5} p={3}>
            <Grid container item xs={12} className="mt-4 assign-bulk">
              <Grid item xs={12} className="mb-3 mt-4">
                <Typography variant="body1" className="mb-2 mt-2">
                  {t("opva.assignToBulkUsers")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
                  {t("opva.step1")}: {t("opva.downloadAssignSkillFormat")}
                </Typography>
              </Grid>
              <Grid item>
                <DownloadExcelButton
                  header={excelHeader}
                  options={excelListOptions}
                  secondOptions={validatedExcelListOptions}
                  secondOptionCell={"D"}
                  optionCell={"B"}
                  listLength={10}
                  defaultValues={defaultValues}
                  fileName={"BulkDatatemplate_assign_skills.xlsx"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {" "}
                <div className="bulkuser-dividers m-3">
                  <Divider variant="middle" orientation="horizontal" flexItem />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="bodyMedium" sx={{ marginBottom: 3 }}>
                  {`${t("opva.step2")}: ${t("opva.fillUploadExcel")}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div
                  className="upload-box-container"
                  onDragOver={handleUploadBoxDragOver}
                  onDrop={handleUploadBoxDrop}
                >
                  <input
                    type="file"
                    accept=".xlsx"
                    hidden
                    id="user-file-upload"
                    onChange={handleFileChange}
                  />
                  <div className="d-flex align-self-center mb-2">
                    {!selectedFile && (
                      <label
                        htmlFor="user-file-upload"
                        className="file-upload-icon"
                      >
                        <FileUploadOutlinedIcon color="primary" />
                      </label>
                    )}
                    {selectedFile && (
                      <Chip
                        color="primary"
                        sx={{ minWidth: "auto", maxWidth: "250px" }}
                        label={selectedFile.name}
                        title={selectedFile.name}
                        onDelete={handleRemoveClick}
                      />
                    )}
                  </div>
                  <div className="d-flex align-self-center mb-2">
                    <label htmlFor="user-file-upload">
                      <Link
                        variant="bodyNormal"
                        underline="none"
                        className="me-1"
                      >
                        <b>{t("opva.clickHere")}</b>
                      </Link>
                      <Typography variant="bodyNormal">
                        {t("opva.uploadOrDropExcelFile")}
                      </Typography>
                    </label>
                  </div>
                </div>
                <div style={{ marginLeft: "25px" }}>
                  {errorMsg && (
                    <Typography variant="body1" color="error">
                      {`Error: ${errorMsg}`}
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6} className="mb-4">
                <Button
                  size="large"
                  variant="contained"
                  sx={{ color: "#FFFFFFCC", textTransform: "none" }}
                  onClick={handleBulkUser}
                  disabled={!selectedFile}
                >
                  {t("opva.confirmUpload")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md lg={1} mt={1}>
            <div className="Dividers">
              <Divider variant="middle" />
            </div>
          </Grid>
          <Grid item xs={12} md={5} mt={1}>
            <Grid container item xs={12} className="mt-4 assign-bulk">
              <Grid item xs={12} className="mb-3 mt-4">
                <Typography variant="body1" className="mb-2 mt-2">
                  {t("opva.addUsersManually")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
                  {t("opva.assignUserManuallyEmail")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className="assign-workflow-container">
                  <div className="d-flex mb-3">
                    <Autocomplete
                      id="search-by-user"
                      className="me-5 w-50"
                      options={unAssignedUsers ? unAssignedUsers : []}
                      autoComplete
                      loadingText={t("opva.userSearching")}
                      noOptionsText={
                        !unAssignedUsers ? (
                          <Typography variant="labelNormal">
                            {t("opva.type2Letters")}
                          </Typography>
                        ) : (
                          <Typography variant="labelNormal">
                            {t("opva.noUsersFound")}
                          </Typography>
                        )
                      }
                      getOptionLabel={(option) => option?.mail}
                      renderOption={(props, option: SkillsToEmployee) => (
                        <li {...props} key={option?.id} value={option.mail}>
                          <Typography variant="labelLarge">
                            {option?.mail} | {option?.firstName}
                          </Typography>
                        </li>
                      )}
                      value={null}
                      onChange={(
                        event: any,
                        value: SkillsToEmployee | null
                      ) => {
                        if (value && typeof value === "object") {
                          handleAssignUser(value);
                        }
                      }}
                      onInputChange={(event: any, newInputValue: string) => {
                        if (newInputValue && newInputValue.length > 3) {
                          getUnAssignedSkillsToEmployee(newInputValue);
                        }
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          label={t("opva.emailId")}
                          {...params}
                          size="small"
                          variant="outlined"
                          placeholder={t("opva.searchByEmail")}
                          className="search-name-text"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <SearchIcon
                                  fontSize="small"
                                  color="inherit"
                                  className="me-2"
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                    {assignData && (
                      <Chip
                        key={assignData?.id}
                        label={assignData?.mail}
                        color="primary"
                        onDelete={handleRemoveAssignUser}
                        sx={{ borderRadius: "8px" }}
                      />
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} className="mb-4">
                <Typography variant="body1" className="m-1">
                  {t("opva.selectSkillLevel")}
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    className="ms-1"
                    row
                    aria-labelledby="row-radio-buttons-group-label"
                    name="skill"
                    value={selectedSkill?.id || ""}
                    onChange={handleSkillChange}
                  >
                    {skillsLevel.map((skill: any) => (
                      <FormControlLabel
                        key={skill.id}
                        value={skill.id}
                        control={
                          <Radio
                            sx={{
                              color: "#0084F4",
                            }}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label={t(`opva.${skill.name.toLowerCase()}`)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} className="mb-4">
                <Typography variant="body1" className="m-1">
                  {t("opva.selectValidation")}
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    className="ms-1"
                    row
                    aria-labelledby="row-radio-buttons-group-label"
                    name="skill"
                    value={selectedSkillValidation?.id || ""}
                    onChange={handleSkillValidationChange}
                  >
                    {skillsValidation.map((skill: any) => (
                      <FormControlLabel
                        key={skill.id}
                        value={skill.id}
                        control={
                          <Radio
                            sx={{
                              color: "#0084F4",
                            }}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        label={t(`opva.${skill.name.toLowerCase()}`)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} className="mb-4">
                <Button
                  size="large"
                  variant="contained"
                  sx={{ color: "#FFFFFFCC", textTransform: "none" }}
                  onClick={assignSkills}
                  disabled={
                    !assignData ||
                    selectedSkill === null ||
                    selectedSkillValidation === null
                  }
                >
                  {t("opva.assignSkill")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box className="assignedusers-container bg-white px-4">
          <Typography variant="subtitle1" m={1}>
            {t("opva.assignedUser")}
          </Typography>

          <TableContainer>
            <Table
              stickyHeader
              sx={{ width: "100%" }}
              size="medium"
              aria-label="AssignSkillz table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="checkbox"
                  ></TableCell>
                  {getAssignWorkflowHeaders().map((headCell: TableHeadCell) => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={
                        assignSort.orderBy === headCell.id
                          ? assignSort.order
                          : false
                      }
                      align={headCell.position}
                      sx={{
                        width: headCell.width + "%",
                      }}
                    >
                      {headCell?.sorting ? (
                        <TableSortLabel
                          active={
                            assignSort.orderBy === headCell.sortingProperty
                          }
                          direction={
                            assignSort.orderBy === headCell.sortingProperty
                              ? assignSort.order
                              : "desc"
                          }
                          onClick={(event) =>
                            handleSorting(
                              headCell.sortingProperty,
                              "assignTable"
                            )
                          }
                        >
                          {headCell.label}
                          {assignSort.orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {assignSort.order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                      {/* <Typography style={{ fontWeight: "500" }}>
                    {column.label}
                  </Typography> */}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isLoadingAssign ? (
                <TableLoading column={getAssignWorkflowHeaders().length + 1} />
              ) : (
                <TableBody>
                  {assignedUsers && assignedUsers.length ? (
                    displayAssignedUsers.map((data: any) => (
                      <TableRow key={data.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="checkbox"
                        >
                          <img
                            src="images/User-Thumb.svg"
                            alt="User Icon"
                            width={40}
                            height={40}
                          />
                        </TableCell>
                        <TableCell>
                          {data?.employee?.firstName} {data?.employee?.lastName}
                        </TableCell>

                        <TableCell>{data?.employee?.employeeId}</TableCell>
                        <TableCell>{data?.employee?.mail}</TableCell>
                        <TableCell>
                          {data?.employee?.assignedRoles.length
                            ? data?.employee?.assignedRoles
                                .map((roles: any) => roles.name)
                                .join(", ")
                            : ""}
                        </TableCell>

                        <TableCell>
                          {data?.validationStatus
                            ? t("opva.validated")
                            : t("opva.notvalidated")}
                        </TableCell>
                        <TableCell>{data?.skillLevelName}</TableCell>
                        <TableCell align="center">
                          <DeleteOutlineIcon
                            color="error"
                            onClick={() => handleRemoveSkillFromEmployee(data)}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        {searchText
                          ? t("opva.searchAsssignDataNotAvailable")
                          : t("opva.noDataAvailable")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
            <div className="d-flex align-items-center justify-content-end">
              <TablePagination
                component="div"
                className="pagination-container mb-2"
                rowsPerPageOptions={[5, 10, 25]}
                count={assignedUsers.length}
                page={assignPage}
                onPageChange={handleChangePageForAssigned}
                rowsPerPage={assignRowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPageForAssigned}
              />
            </div>
          </TableContainer>
        </Box>
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{t("opva.assignToUser")}</Typography>
            <IconButton aria-label="close" onClick={() => setOpenDialog(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography variant="body1" className="m-1">
            {t("opva.selectedUser")}
          </Typography>
          {assignData && (
            <div>
              <Chip
                key={assignData?.id}
                label={assignData?.mail}
                color="primary"
                onDelete={handleRemoveAssignUser}
                style={{ margin: "4px" }}
              />
            </div>
          )}

          <div>
            <Typography variant="body1" className="m-1">
              {t("opva.selectSkillLevel")}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                className="ms-1"
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="skill"
                value={selectedSkill?.id || ""}
                onChange={handleSkillChange}
              >
                {skillsLevel.map((skill: any) => (
                  <FormControlLabel
                    key={skill.id}
                    value={skill.id}
                    control={
                      <Radio
                        sx={{
                          color: "#0084F4",
                        }}
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    label={t(`opva.${skill.name.toLowerCase()}`)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <Typography variant="body1" className="m-1">
              {t("opva.selectValidation")}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                className="ms-1"
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="skill"
                value={selectedSkillValidation?.id || ""}
                onChange={handleSkillValidationChange}
              >
                {skillsValidation.map((skill: any) => (
                  <FormControlLabel
                    key={skill.id}
                    value={skill.id}
                    control={
                      <Radio
                        sx={{
                          color: "#0084F4",
                        }}
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    label={t(`opva.${skill.name.toLowerCase()}`)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="dialog-btn">
          <Button
            color="primary"
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={() => assignSkillToEmployee(assignData?.id)}
            disabled={
              !assignData ||
              selectedSkill === null ||
              selectedSkillValidation === null
            }
          >
            {t("opva.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

import "./Login.scss";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";

import { SignupHereLink } from "../../../shared/components/signup-here-link/SignupHereLink";
import { LoginFormData } from "../models/LoginModels";
import { useAPI } from "../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";
import { AuthenticationContext } from "../../../shared/Contexts";
import { AuthUserDetailsModel } from "../../../shared/models/ContextModels";
import { NotificationContext } from "../../notification/contexts/NotificationContext";

const initialLoginData = {
  emailAddress: "",
  otp: null,
  error: {
    isEmailAddressEmpty: false,
    isEmailAddressValid: true,
    isOtpEmpty: false,
    isOtpValid: true,
  },
} as LoginFormData;

export default function Login() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { httpPost, httpGet, handleAlertBar } = useAPI();
  const authenticate = useContext(AuthenticationContext);
  const [loginData, setLoginData] = useState(initialLoginData);
  const [isOTPTriggered, setIsOTPTriggered] = useState(false);
  const [isResendOTP, setIsResendOTP] = useState(false);
  const [resendOTPMinutes, setResendOTPMinutes] = useState(0);
  const [resendOTPSeconds, setResendOTPSeconds] = useState(0);
  const { updateHaveUnreadNotifications } = useContext(NotificationContext);

  useEffect(() => {
    const interval = setInterval(() => {
      if (resendOTPSeconds > 0) {
        setResendOTPSeconds(resendOTPSeconds - 1);
      }

      if (resendOTPSeconds === 0) {
        if (resendOTPMinutes === 0) {
          setIsResendOTP(true);
          clearInterval(interval);
        } else {
          setResendOTPSeconds(59);
          setResendOTPMinutes(resendOTPMinutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [resendOTPSeconds]);

  const handleOnBlurEmailAddress = (value: string) => {
    const validation = {
      isEmailAddressEmpty: value ? false : true,
    };
    setLoginData((previousData) => ({
      ...previousData,
      error: { ...previousData.error, ...validation },
    }));
  };

  const handleOnChangeEmailAddress = (value: string) => {
    const validation = {
      isEmailAddressEmpty: value ? false : true,
      isEmailAddressValid: value
        ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? true
          : false
        : true,
    };
    setLoginData((previousData) => ({
      ...previousData,
      emailAddress: value,
      error: { ...previousData.error, ...validation },
    }));
  };

  const handleOnBlurOTP = (value: string) => {
    const validation = {
      isOtpEmpty: value ? false : true,
    };
    setLoginData((previousData) => ({
      ...previousData,
      error: { ...previousData.error, ...validation },
    }));
  };

  const handleOnChangeOTP = (value: any) => {
    const validation = {
      isOtpEmpty: false,
    };
    setLoginData((previousData) => ({
      ...previousData,
      otp: value,
      error: { ...previousData.error, ...validation },
    }));
  };

  const handleKeyDown = (e: any) => {
    const isValidInput =
      !isNaN(e.key) || e.key === "Backspace" || e.key === "Delete";
    const isMaxLengthReached = e.target.value.length >= 6;
    if (e.ctrlKey && e.key === "v" && !isMaxLengthReached) {
      return;
    }

    // Prevent non-numeric characters and more than 6 digits
    if (
      (!isValidInput && e.key !== "Backspace" && e.key !== "Delete") ||
      (isMaxLengthReached && e.key !== "Backspace" && e.key !== "Delete")
    ) {
      e.preventDefault();
    }
  };

  const handleResetLogin = () => {
    setLoginData({ ...initialLoginData, emailAddress: loginData.emailAddress });
    setIsOTPTriggered(false);
    setIsResendOTP(false);
    setResendOTPSeconds(0);
  };

  const handlePostLoginRedirection = (jwtToken: string) => {
    const jwtTokenDecoded = jwtDecode<AuthUserDetailsModel>(jwtToken);
    if (
      jwtTokenDecoded &&
      jwtTokenDecoded.employeeRoles &&
      jwtTokenDecoded.employeeRoles.length &&
      !(
        jwtTokenDecoded.employeeRoles.filter((element) =>
          [OPTIVAL_CONSTANTS.EMPLOYEE].includes(element)
        ).length && jwtTokenDecoded.employeeRoles.length === 1
      )
    ) {
      localStorage.setItem("jwtToken", jwtToken);
      authenticate.updateToken(jwtToken);
      fetchNotificationData();
      const languageCode =
        jwtTokenDecoded.languageCode || OPTIVAL_CONSTANTS.DEFAULT_LANGUAGE_CODE;
      i18n.changeLanguage(languageCode);
      localStorage.setItem(
        OPTIVAL_CONSTANTS.LANGUAGE_CODE_SESSION_KEY,
        languageCode
      );
      localStorage.setItem(
        OPTIVAL_CONSTANTS.EMPLOYEE_PREFERRED_LANGUAGE,
        jwtTokenDecoded.employeePreferredLanguage
      );
      localStorage.setItem(
        OPTIVAL_CONSTANTS.LANGUAGE_ID,
        jwtTokenDecoded.languageId
      );
      if (
        jwtTokenDecoded.employeeRoles.filter((element) =>
          [
            OPTIVAL_CONSTANTS.SUPER_ADMIN,
            OPTIVAL_CONSTANTS.SALES_TEAM,
            OPTIVAL_CONSTANTS.CUSTOMER_SUCCESS,
          ].includes(element)
        ).length
      ) {
        navigate("/manage-organisation");
      } else {
        navigate("/home");
      }
    } else {
      navigate("/mobile-app");
    }
  };

  const fetchNotificationData = async () => {
    const queryParams = {
      page: 0,
      size: 1000,
      sortColumn: "sendAt",
      isDescending: true,
    };
    await httpGet(
      OPTIVAL_CONSTANTS.API_URLS.GET_NOTIFICATIONS,
      queryParams
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            const unreadNotification = response?.data?.haveUnreadNotifications;
            updateHaveUnreadNotifications(unreadNotification);
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {
      }
    );
  };

  const getResendOTPCountCookies = (): string => {
    // To prevent the for loop in the first place assign an empty array
    // in case there are no cookies at all.
    let resendCount = "";
    var cookies = document.cookie ? document.cookie.split(";") : [];
    if (cookies.length > 0) {
      const userCookie = cookies.find((x) =>
        x.trim().startsWith(loginData.emailAddress + "=")
      );
      if (userCookie) {
        resendCount = userCookie.split("=").slice(1).join("=");
      }
    }
    return resendCount;
  };

  const generateOTP = () => {
    let resendOTPCount = Number(getResendOTPCountCookies());
    if (
      !resendOTPCount ||
      resendOTPCount < OPTIVAL_CONSTANTS.RESEND_OTP_COUNT
    ) {
      const queryParams = {
        mail: loginData.emailAddress,
      };
      httpPost(OPTIVAL_CONSTANTS.API_URLS.AUTH_LOGIN, null, queryParams).then(
        (response) => {
            if (response.data.error) {
              console.log(response?.data?.code)
              handleAlertBar(
                "error",
                response?.data?.message || t("opva.UN_REGISTERED_EMAIL")
              );
            } else {
              handleAlertBar("success", t("opva.emailOTPGeneratedSuccessfully"));
              setResendOTPSeconds(30);
              setIsResendOTP(false);
              setIsOTPTriggered(true);
              resendOTPCount = resendOTPCount + 1;
              let addCookie = `${loginData.emailAddress}=${resendOTPCount}`;
              if (resendOTPCount === OPTIVAL_CONSTANTS.RESEND_OTP_COUNT) {
                addCookie += `; expires=${new Date(
                  new Date().getTime() +
                    OPTIVAL_CONSTANTS.RESEND_OTP_COUNT_COOKIE_EXPIRE_TIME
                ).toUTCString()}`;
              }
              document.cookie = addCookie;
            }
        },
        (error) => {}
      );
    } else {
      handleAlertBar("warning", OPTIVAL_CONSTANTS.RESEND_OTP_REACHED_MSG);
    }
  };

  const verifyOTP = () => {
    const queryParams = {
      mail: loginData.emailAddress,
      otp: loginData.otp,
    };
    httpPost(
      OPTIVAL_CONSTANTS.API_URLS.AUTH_LOGIN_VERIFY_OTP,
      null,
      queryParams
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || OPTIVAL_CONSTANTS.SERVICE_UNAVAILABLE
            );
          } else {
            if (response.data?.token) {
              const jwtToken: string = response.data?.token || "";
              handlePostLoginRedirection(jwtToken);
              document.cookie = `${
                loginData.emailAddress
              }=''; expires=${new Date(0).toUTCString()}`;
            } else {
              handleAlertBar(
                "error",
                OPTIVAL_CONSTANTS.OTP_VERFIY_LOGIN_ERROR_MSG
              );
            }
          }
        } else {
          handleAlertBar("error", OPTIVAL_CONSTANTS.SERVICE_UNAVAILABLE);
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    let languageCode = OPTIVAL_CONSTANTS.DEFAULT_LANGUAGE_CODE;
    const browserLanguage = navigator.language;
    if (browserLanguage) {
      languageCode =
        browserLanguage.split("-")[0] ||
        OPTIVAL_CONSTANTS.DEFAULT_LANGUAGE_CODE;
    }
    i18n.changeLanguage(languageCode);
  }, []);

  const backToUrl = () => {
      window.location.href = process.env?.REACT_APP_OPVA_WEBSITE_URL
        ? process.env.REACT_APP_OPVA_WEBSITE_URL
        : "https://opva.ai";
  }

  return (
    <div className="login-container bg-dark-blue">
      <Grid container className="login-grid-container w-75">
        <Grid item xs md lg={5} className="pe-4">
          <div className="d-flex flex-column">
            <img
              src="images/Logo.jpg"
              width={120}
              height={40}
              title="Optival"
              alt="Optival Logo"
              className="mb-3"
              onClick={backToUrl}
            ></img>
            {/* <Typography variant="bodySmall" className="mb-4">
              {t("opva.loginDescription")}
            </Typography> */}
          </div>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className="divider-line mx-3"
        />
        <Grid item xs md lg={6} ml={3}>
          <div className="d-flex flex-column">
            <Typography variant="h3" className="mb-4 mt-4">
              {t("opva.signIn")}
            </Typography>
            <Typography variant="labelSmall">
              {t("opva.emailAddress")}
              <span className="asterisk">*</span>
            </Typography>
            <TextField
              required
              variant="standard"
              name="emailAddress"
              value={loginData.emailAddress}
              onBlur={(e) => {
                handleOnBlurEmailAddress(e.target.value);
              }}
              onChange={(e) => {
                handleOnChangeEmailAddress(e.target.value);
              }}
              error={
                loginData?.error?.isEmailAddressEmpty ||
                !loginData?.error?.isEmailAddressValid
              }
              helperText={
                (loginData?.error?.isEmailAddressEmpty &&
                  t("opva.enterEmail")) ||
                (!loginData?.error?.isEmailAddressValid &&
                  t("opva.enterValidEmail"))
              }
              disabled={isOTPTriggered}
            />
            {isOTPTriggered && (
              <>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <Typography variant="bodySmall" className="triggered-otp">
                    {t("opva.tiggerOtp")}
                  </Typography>
                  <Link
                    variant="labelNormal"
                    underline="none"
                    onClick={handleResetLogin}
                  >
                    <b>{t("opva.changeEmail")}</b>
                  </Link>
                </div>
                <Typography variant="labelSmall" className="mt-3">
                  {t("opva.emailOtp")}
                  <span className="asterisk">*</span>
                </Typography>
                <TextField
                  required
                  variant="standard"
                  name="otp"
                  value={loginData.otp ?? ""}
                  onKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  onBlur={(e) => {
                    handleOnBlurOTP(e.target.value);
                  }}
                  onChange={(e) => {
                    handleOnChangeOTP(e.target.value);
                  }}
                  error={
                    loginData?.error?.isOtpEmpty ||
                    !loginData?.error?.isOtpValid
                  }
                  helperText={
                    (loginData?.error?.isOtpEmpty && t("opva.enterOtp")) ||
                    (!loginData?.error?.isOtpValid && t("opva.enterValidOtp"))
                  }
                />

                <div className="mt-2 text-end">
                  <Button
                    disableElevation
                    size="small"
                    sx={{ textTransform: "none", padding: 0 }}
                    disabled={!isResendOTP}
                    onClick={generateOTP}
                  >
                    <b>{t("opva.resendOTP")}</b>
                  </Button>
                  {(resendOTPMinutes > 0 || resendOTPSeconds > 0) && (
                    <Typography variant="labelSmall" className="ms-2">
                      ({resendOTPMinutes}:
                      {resendOTPSeconds < 10
                        ? `0${resendOTPSeconds}`
                        : resendOTPSeconds}
                      )
                    </Typography>
                  )}
                </div>
              </>
            )}

            <div className="d-flex justify-content-between mt-5">
              <SignupHereLink />
              {!isOTPTriggered && (
                <Button
                  variant="contained"
                  sx={{ width: "30%" }}
                  disabled={
                    !loginData.emailAddress ||
                    !loginData?.error?.isEmailAddressValid
                  }
                  onClick={generateOTP}
                >
                  {t("opva.sendOtp")}
                </Button>
              )}
              {isOTPTriggered && (
                <Button
                  variant="contained"
                  sx={{ width: "30%" }}
                  disabled={!loginData.otp}
                  onClick={verifyOTP}
                >
                  {t("opva.verify")}
                </Button>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

import { WorkflowListModel } from "../../workflow/models/Workflow";

export interface SkillsData {
  id: number;
  name: string;
  description: string;
  createdAt: string;
  modifiedAt: string;
  createdBy: string;
  modified: string;
  usersAssigned: number;
  isDescriptionViewMore?: boolean;
  validatedCount?: number;
}

export interface SkillsAssignGroupsUsersModel {
  employee: SkillsAssignUsersModel,
  group: SkillsAssignGroups
}

export interface SkillsAssignUsersModel {
  id: number,
  firstName: string,
  lastName: string,
  mail: string,
  nameAutocomplete: string,
  fullNameAutocomplete: string,
  createdAt: string,
  organizationId: number
}

export interface SkillsAssignGroups {
  groupId: number,
  name: string,
  nameAutocomplete: string,
  createdAt: string,
  organizationId: number
}

export interface ISkillsLevel {
  id: number,
  name: string,
  description: string
}

export interface WorkflowAvailableProps {
  levelId: string;
  levelName?: string;
  row?: any;
  collapseClose: () => void;
  workflowDataList: WorkflowListModel[];
  selectGroupsList: SkillsAssignGroups[];
  eligibleUsersDetail: any;
}

export const dummySkillsLevels = [
  {
      "id": 1,
      "name": "Beginner",
      "description": "Beginner level skill"
  },
  {
      "id": 2,
      "name": "Intermediate",
      "description": "Intermediate level skill"
  },
  {
      "id": 3,
      "name": "Advanced",
      "description": "Advanced level skill"
  },
  {
      "id": 4,
      "name": "Expert",
      "description": "Expert level skill"
  }
]

import {
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "./TopSkillsTable.scss";
import { useTranslation } from "react-i18next";
import { TableHeadCell } from "../../../../../shared/models/TableModels";
import { useAPI } from "../../../../../shared/services/api/API";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../../../../shared/SpinnerContext";
import { OPTIVAL_CONSTANTS } from "../../../../../shared/Constants";
import { TableLoading } from "../../../../../shared/components/skeleton/table-loading/TableLoading";
import { SkillsContext } from "../../../contexts/SkillsContext";
import { SharedApi } from "../../../../../shared/services/shared-api/SharedApi";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const TopSkillsTable = () => {
  const { t } = useTranslation();
  const { httpGet, handleAlertBar } = useAPI();
  const { topViewMore, updateTopSkillLessMore } = useContext(SkillsContext);
  const { isSpinnerShow } = useContext(SpinnerContext);
  const [skillsLevel, setSkillsLevel] = useState<any>([]);
  const [topSkillsHeader, setTopSkillsHeader] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const { getColorForLevel } = SharedApi();
  const tablesPerPage = 3; //Display 3 tables at a time
  const [currentPage, setCurrentPage] = useState(0);
  const chunkedOrders = [];
  for (let i = 0; i < tableData.length; i +=10) {
    chunkedOrders.push(tableData.slice(i,i+10));
  }

  const handleNext = () => {
    if((currentPage + 1) * tablesPerPage < chunkedOrders.length) {
      setCurrentPage(currentPage + 1);
    }
  }
  const handlePrevious = () => {
    if(currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  } 

  const startIndex = currentPage * tablesPerPage;
  const endIndex = startIndex + tablesPerPage;
  const currentTables = chunkedOrders.slice(startIndex, endIndex);

  useEffect(() => {
    getTopSkillsData();
  }, []);

  const getTopSkillsData = () => {
    const lang = localStorage.getItem(OPTIVAL_CONSTANTS.EMPLOYEE_PREFERRED_LANGUAGE);
    const queryParams = {
      language: lang
    };
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_TOP_SKILLS, queryParams).then((response)=>{
        if(response && response.data) {
            if(response.data.error) {
                handleAlertBar('error', response.data.message);
            } else {
              const levels = response.data.levels;
              // Generate table header
              const tableHeader = [{ id: '', label: "" }];
              levels.forEach((level: any) => {
                tableHeader.push({ id: level.id, label: level.name });
              });
              tableHeader.push({ id: t("opva.%ofTotal"), label: t("opva.%ofTotal") });
              
              setSkillsLevel(levels);
              setTopSkillsHeader(tableHeader);
              setTableData(response.data.skillMatrix);
            }
        } else {
            handleAlertBar("error", t("opva.serviceUnavailable"));
          }
    })
  }

  const renderTable = (data: any) => {
    return (<TableContainer className="skills-table">
    <Table size="medium" aria-label="skills table">
      <TableHead>
        <TableRow>
          {topSkillsHeader.map((headCell: TableHeadCell) => (
            <TableCell
              align="center"
              key={headCell.id}
              className="py-1"
              sx={{
                backgroundColor: getColorForLevel(headCell.id),
              }}
            >
              <Typography>
                {t(headCell.id)}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {isSpinnerShow ? (
        <TableLoading column={4} />
      ) : (
        <TableBody>
          {data && data.length ? (
            data.map((row: any, index: number) => (
              <TableRow key={index}>
                {topSkillsHeader.map((header: any) => (
                  <TableCell align="center" key={header.id}>
                    {header?.label === "" ? (
                  <div className="skills-name-text">
                  <Typography
                  align="left"
                    sx={{ width: "70px", 
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap"
                    }}
                    title={row.name}
                    variant="bodySmall"
                    className="fw-bold"
                  >
                    {row.name}
                  </Typography>
                </div>
                          ) : header.label === t("opva.%ofTotal") ? (
                            <Typography align="center" variant="bodySmall">
                            {`${row.percentage} %`}
                          </Typography>
                          ) : (
                            <Typography align="center" variant="bodySmall">
                      {row.levels[header.id] || '-'}
                    </Typography>
                          )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
              {t("opva.noDataAvailable")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      )}
    </Table>
    </TableContainer>)
  }

  return (
    <>
    {!topViewMore ? (<div className="skills-table-container" style={{filter: "drop-shadow(0.5px 3px 3px #00000040)"}}>
      <Grid container display={'flex'} justifyContent={'space-between'}>
        <Grid item xs={10} sm={10} md={10} lg={11}>
          <h5 className="fw-bold">{t("opva.top30Skills")}</h5>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={1} textAlign="right">
        <Link
                component="button"
                color={"#00000"}
                title={"expand"}
                onClick={() => updateTopSkillLessMore(true)}
              >
                <OpenInFullIcon fontSize="small" />
              </Link>
        </Grid>
      </Grid>
      <div>{renderTable(tableData.slice(0,3))}</div>
    </div>) : (<div className="skills-table-container" style={{backgroundColor: "#ffffff"}}>
    <Grid container display={'flex'} justifyContent={'space-between'}>
    <Grid item xs={8} sm={8} md={10} lg={10}>
    <Typography variant="h5">
              {t("opva.top30Skills")}
              </Typography>
      </Grid>
      <Grid item xs={4} sm={4} md={1} lg={1} textAlign="right">
      <Link
          title={t("opva.backToOverview")}
          color={"#00000"}
          component="button"
          className="mb-2"
          onClick={() => updateTopSkillLessMore(false)}
        >
          <CloseFullscreenIcon fontSize="small" />
        </Link>
    </Grid>
    </Grid>
    <Grid container display={'flex'} direction={'row'} spacing={2}>
      {currentTables.map((chunk, index) => (
        <Grid item sm={12} md={4} lg={4} key={index}><Paper sx={{backgroundColor: "#fafbfc"}}><div className="mx-2">{renderTable(chunk)}</div></Paper></Grid>
      ))}
    </Grid>
    <div className="pagination mt-2">
    <Link component="button" title={t("opva.previous")} onClick={handlePrevious} disabled={currentPage === 0} className="mx-2">
                      <ArrowBackIosIcon
                        fontSize="small"
                        color={currentPage === 0 ? 'disabled' : 'primary'}
                      />
                    </Link>
    <Link component="button" title={t("opva.next")} onClick={handleNext} disabled={endIndex >= chunkedOrders?.length}>
                      <ArrowForwardIosIcon
                        fontSize="small"
                        color={endIndex >= chunkedOrders?.length ? 'disabled' : 'primary'}
                      />
    </Link>
    </div>
  </div>)}
    </>
  );
};
